/**
 * Human-readable resolution of error paths commonly found
 * in various places.
 */
const ErrorPaths = {
  id: "appId",
  "/id": "appId",
  apps: "appId",
  "/": "general"
};

module.exports = ErrorPaths;
