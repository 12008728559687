const EventTypes = {};
[
  "MESOS_INITIALIZE_LOG_CHANGE",
  "MESOS_INITIALIZE_LOG_REQUEST_ERROR",
  "MESOS_LOG_CHANGE",
  "MESOS_LOG_REQUEST_ERROR",
  "MARATHON_APPS_CHANGE",
  "MARATHON_APPS_ERROR",
  "MARATHON_DEPLOYMENT_ROLLBACK_ERROR",
  "MARATHON_DEPLOYMENT_ROLLBACK_SUCCESS",
  "MARATHON_DEPLOYMENTS_CHANGE",
  "MARATHON_DEPLOYMENTS_ERROR",
  "MARATHON_GROUP_CREATE_ERROR",
  "MARATHON_GROUP_CREATE_SUCCESS",
  "MARATHON_GROUP_DELETE_ERROR",
  "MARATHON_GROUP_DELETE_SUCCESS",
  "MARATHON_GROUP_EDIT_ERROR",
  "MARATHON_GROUP_EDIT_SUCCESS",
  "MARATHON_GROUPS_CHANGE",
  "MARATHON_GROUPS_ERROR",
  "MARATHON_INSTANCE_INFO_SUCCESS",
  "MARATHON_INSTANCE_INFO_ERROR",
  "MARATHON_POD_INSTANCE_KILL_SUCCESS",
  "MARATHON_POD_INSTANCE_KILL_ERROR",
  "MARATHON_QUEUE_CHANGE",
  "MARATHON_QUEUE_ERROR",
  "MARATHON_SERVICE_CREATE_ERROR",
  "MARATHON_SERVICE_CREATE_SUCCESS",
  "MARATHON_SERVICE_DELETE_ERROR",
  "MARATHON_SERVICE_DELETE_SUCCESS",
  "MARATHON_SERVICE_EDIT_ERROR",
  "MARATHON_SERVICE_EDIT_SUCCESS",
  "MARATHON_SERVICE_RESTART_ERROR",
  "MARATHON_SERVICE_RESTART_SUCCESS",
  "MARATHON_SERVICE_VERSION_CHANGE",
  "MARATHON_SERVICE_VERSION_ERROR",
  "MARATHON_SERVICE_VERSIONS_CHANGE",
  "MARATHON_SERVICE_VERSIONS_ERROR",
  "MARATHON_TASK_KILL_SUCCESS",
  "MARATHON_TASK_KILL_ERROR",
  "TASK_DIRECTORY_CHANGE",
  "TASK_DIRECTORY_ERROR",
  "NODE_STATE_ERROR",
  "NODE_STATE_SUCCESS"
].forEach(function(eventType) {
  EventTypes[eventType] = eventType;
});

module.exports = EventTypes;
