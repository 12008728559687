module.exports = (function() {

var REPLACE_MESSAGE_TEMPLATE = /\{\{([^\}]+)\}\}/g;

function RAMLError(path, context, type, _messageVariables) {
  var messageVariables = _messageVariables || {};
  var message = context.ERROR_MESSAGES[type];
  Object.defineProperties(this, {
    message: {
      enumerable: true,
      get: function() {
        if (typeof message === 'function') {
          return message(messageVariables, path);
        }

        return message.replace(REPLACE_MESSAGE_TEMPLATE, function(match) {
          return ''+messageVariables[match.slice(2,-2)] || '';
        });
      }
    },
    path: {
      enumerable: true,
      value: path
    },
    type: {
      enumerable: true,
      value: type
    },
    variables: {
      enumerable: true,
      value: messageVariables
    }
  });
}

var DEFAULT_ERROR_MESSAGES = {
  "ENUM": "Must be one of {{values}}",
  "TYPE_NOT_STRING": "Must be a string",
  "PROP_IS_MISSING": "Must be defined",
  "TYPE_NOT_OBJECT": "Must be an object",
  "NUMBER_TYPE": "Must be of type `{{type}}`",
  "NUMBER_MIN": "Must be bigger than or equal to {{value}}",
  "TYPE_NOT_INTEGER": "Must be an integer number",
  "TYPE_NOT_NUMBER": "Must be a number",
  "NUMBER_MAX": "Must be smaller than or equal to {{value}}",
  "ITEMS_UNIQUE": "Must contain only unique items",
  "TYPE_NOT_ARRAY": "Must be an array",
  "STRING_PATTERN": "Must match the pattern `{{pattern}}`",
  "LENGTH_MIN": "Must be at least {{value}} characters long",
  "TYPE_NOT_DATETIME": "Must be a date/time string",
  "ITEMS_MIN": "Must contain at least {{value}} items in the array",
  "LENGTH_MAX": "Must be at most {{value}} characters long",
  "TYPE_NOT_BOOLEAN": "Must be a boolean value",
  "ITEMS_MAX": "Must contain at most {{value}} items in the array"
}

var DEFAULT_CONTEXT = {
	ENUMS: [
		["fixed"],
		["youngest_first","oldest_first"],
		["disabled"],
		["unique","cluster","group_by","like","unlike","max_per","is"],
		["container","container/bridge","host"],
		["docker","appc"],
		["http","https"],
		["root","path","mount"],
	],
	HELPERS: [
		new Float32Array(1),
	],
	REGEX: [
		new RegExp('^(\/?((\.\.)|(([a-z0-9]|[a-z0-9][a-z0-9\-]*[a-z0-9])\.)*([a-z0-9]|[a-z0-9][a-z0-9\-]*[a-z0-9]))?($|\/))+$'),
		new RegExp('.*'),
		new RegExp('^[a-zA-Z_][a-zA-Z0-9_]{0,253}?$'),
		new RegExp('^[a-z0-9]([-a-z0-9]*[a-z0-9])?$'),
		new RegExp('^[a-z0-9]([-a-z0-9_]*[a-z0-9])?$'),
	],
}

var RAMLValidator = function(config) {
	if (!config) config = {};
	var context = Object.assign({}, DEFAULT_CONTEXT);
	
	// Override errorMessages through config
	context.ERROR_MESSAGES = Object.assign(
		{},
		DEFAULT_ERROR_MESSAGES,
		config.errorMessages
	)
	
	var PrivateValidators = {
		/**
		 * 1
		 * 
		 * This is an in-line specialization of IntegerType
		 * with the following constraints:
		 * 
		 * - format: int32
		 * - minimum: 0
		 */
		inlineIntegerType_1da39b1015745f6788e8467b4b016d08: function(value, path) {
			var errors = [];
			path = path || [];
			if (isNaN(value) || (value % 1 !== 0)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_INTEGER"));
			} else {
				if (!((value % 1 === 0) && (value >= -2147483648) && (value <= 2147483647))) {
					errors.push(new RAMLError(path, context, "NUMBER_TYPE", {"type":"int32"}));
				}
				if (value < 0) {
					errors.push(new RAMLError(path, context, "NUMBER_MIN", {"value":0}));
				}
			}
			return errors;
		},
	
		/**
		 * 1
		 * 
		 * This is an in-line specialization of NumberType
		 * with the following constraints:
		 * 
		 * - minimum: 0
		 */
		inlineNumberType_57bfd98cf72e8ce725369576269362ad: function(value, path) {
			var errors = [];
			path = path || [];
			if (isNaN(value)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_NUMBER"));
			} else {
				if (value < 0) {
					errors.push(new RAMLError(path, context, "NUMBER_MIN", {"value":0}));
				}
			}
			return errors;
		},
	
		/**
		 * 1
		 * 
		 * This is an in-line specialization of NumberType
		 * with the following constraints:
		 * 
		 * - minimum: 0
		 * - maximum: 1
		 */
		inlineNumberType_755aef13143071d7e09ebe3f57078b7f: function(value, path) {
			var errors = [];
			path = path || [];
			if (isNaN(value)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_NUMBER"));
			} else {
				if (value < 0) {
					errors.push(new RAMLError(path, context, "NUMBER_MIN", {"value":0}));
				}
				if (value > 1) {
					errors.push(new RAMLError(path, context, "NUMBER_MAX", {"value":1}));
				}
			}
			return errors;
		},
	
		/**
		 * [
		 *   {
		 *     "fieldName": "hostname",
		 *     "operator": "UNIQUE"
		 *   }
		 * ]
		 * 
		 * This is an in-line specialization of Constraint
		 * with the following constraints:
		 * 
		 * - uniqueItems: true
		 * - items: Constraint
		 */
		inlineConstraint_3b1f04aede5a1e865648311c626a62e2: function(value, path) {
			var errors = [];
			path = path || [];
			if (!Array.isArray(value)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_ARRAY"));
			} else {
				if ((function() {
					var valuesSoFar = Object.create(null);
					for (var i = 0; i < value.length; ++i) {
						var val = JSON.stringify(value[i]);
						if (val in valuesSoFar) {
							return true;
						}
						valuesSoFar[val] = true;
					}
					return false;
				})()) {
					errors.push(new RAMLError(path, context, "ITEMS_UNIQUE"));
				}
				errors = value.reduce(function(errors, value, i) {
					return errors.concat(
						Validators.Constraint(value, path.concat([i]))
					);
				}, errors);
			}
			return errors;
		},
	
		/**
		 * [
		 *   "public-facing"
		 * ]
		 * 
		 * This is an in-line specialization of StringType
		 * with the following constraints:
		 * 
		 * - items: StringType
		 */
		inlineStringType_c16b318f4fe8b85b2a89ed06127bb779: function(value, path) {
			var errors = [];
			path = path || [];
			if (!Array.isArray(value)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_ARRAY"));
			} else {
				errors = value.reduce(function(errors, value, i) {
					return errors.concat(
						Validators.StringType(value, path.concat([i]))
					);
				}, errors);
			}
			return errors;
		},
	
		/**
		 * "some value"
		 * 
		 * This is an in-line specialization of DateTimeType
		 * with the following constraints:
		 * 
		 * 
		 */
		inlineDateTimeType_d41d8cd98f00b204e9800998ecf8427e: function(value, path) {
			var errors = [];
			path = path || [];
			if (isNaN(new Date(value).getTime())) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_DATETIME"));
			} else {
			}
			return errors;
		},
	
		/**
		 * "some value"
		 * 
		 * This is an in-line specialization of StringType
		 * with the following constraints:
		 * 
		 * 
		 */
		inlineStringType_d41d8cd98f00b204e9800998ecf8427e: function(value, path) {
			var errors = [];
			path = path || [];
			if (typeof value != "string") {
				errors.push(new RAMLError(path, context, "TYPE_NOT_STRING"));
			} else {
			}
			return errors;
		},
	
		/**
		 * [
		 *   {
		 *     "name": "some value",
		 *     "exec": {
		 *       "command": {
		 *         "shell": "some value"
		 *       },
		 *       "overrideEntrypoint": true
		 *     },
		 *     "resources": {
		 *       "cpus": 0.1,
		 *       "mem": 512,
		 *       "disk": 0.2,
		 *       "gpus": 1
		 *     },
		 *     "endpoints": [
		 *       {
		 *         "name": "some value",
		 *         "containerPort": 1,
		 *         "hostPort": 1,
		 *         "protocol": [
		 *           "some value"
		 *         ],
		 *         "labels": {},
		 *         "networkNames": [
		 *           "some value"
		 *         ]
		 *       }
		 *     ],
		 *     "image": {
		 *       "kind": "some value",
		 *       "id": "mesosphere/marathon:1.3.0",
		 *       "pullConfig": {
		 *         "secret": "some value"
		 *       },
		 *       "forcePull": true,
		 *       "labels": {}
		 *     },
		 *     "environment": {},
		 *     "user": "some value",
		 *     "healthCheck": {
		 *       "http": {
		 *         "endpoint": "some value",
		 *         "path": "some value",
		 *         "scheme": "HTTP"
		 *       },
		 *       "tcp": {
		 *         "endpoint": "some value"
		 *       },
		 *       "exec": {
		 *         "command": {
		 *           "shell": "some value"
		 *         }
		 *       },
		 *       "gracePeriodSeconds": 300,
		 *       "intervalSeconds": 60,
		 *       "maxConsecutiveFailures": 3,
		 *       "timeoutSeconds": 20,
		 *       "delaySeconds": 15
		 *     },
		 *     "check": {
		 *       "http": {
		 *         "endpoint": "some value",
		 *         "path": "some value",
		 *         "scheme": "HTTP"
		 *       },
		 *       "tcp": {
		 *         "endpoint": "some value"
		 *       },
		 *       "exec": {
		 *         "command": {
		 *           "shell": "some value"
		 *         }
		 *       },
		 *       "intervalSeconds": 60,
		 *       "timeoutSeconds": 20,
		 *       "delaySeconds": 15
		 *     },
		 *     "volumeMounts": [
		 *       {
		 *         "name": "some value",
		 *         "mountPath": "some value",
		 *         "readOnly": true
		 *       }
		 *     ],
		 *     "artifacts": [
		 *       {
		 *         "uri": "http://download.me/file.tgz",
		 *         "extract": true,
		 *         "executable": true,
		 *         "cache": false
		 *       }
		 *     ],
		 *     "labels": {},
		 *     "lifecycle": {
		 *       "killGracePeriodSeconds": 1
		 *     },
		 *     "tty": true
		 *   }
		 * ]
		 * 
		 * This is an in-line specialization of PodContainer
		 * with the following constraints:
		 * 
		 * - items: PodContainer
		 * - minItems: 1
		 */
		inlinePodContainer_5023763c58cb378ebf05c94ca2bf36a7: function(value, path) {
			var errors = [];
			path = path || [];
			if (!Array.isArray(value)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_ARRAY"));
			} else {
				errors = value.reduce(function(errors, value, i) {
					return errors.concat(
						Validators.PodContainer(value, path.concat([i]))
					);
				}, errors);
				if (value.length < 1) {
					errors.push(new RAMLError(path, context, "ITEMS_MIN", {"value":1}));
				}
			}
			return errors;
		},
	
		/**
		 * [
		 *   {
		 *     "name": "some value",
		 *     "persistent": {
		 *       "type": "some value",
		 *       "size": 1,
		 *       "maxSize": 1,
		 *       "profileName": "some value",
		 *       "constraints": [
		 *         [
		 *           "some value"
		 *         ]
		 *       ]
		 *     }
		 *   }
		 * ]
		 * 
		 * This is an in-line specialization of PodVolume
		 * with the following constraints:
		 * 
		 * - items: PodVolume
		 */
		inlinePodVolume_c16b318f4fe8b85b2a89ed06127bb779: function(value, path) {
			var errors = [];
			path = path || [];
			if (!Array.isArray(value)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_ARRAY"));
			} else {
				errors = value.reduce(function(errors, value, i) {
					return errors.concat(
						Validators.PodVolume(value, path.concat([i]))
					);
				}, errors);
			}
			return errors;
		},
	
		/**
		 * [
		 *   {
		 *     "name": "some value",
		 *     "mode": "container",
		 *     "labels": {}
		 *   }
		 * ]
		 * 
		 * This is an in-line specialization of Network
		 * with the following constraints:
		 * 
		 * - items: Network
		 */
		inlineNetwork_c16b318f4fe8b85b2a89ed06127bb779: function(value, path) {
			var errors = [];
			path = path || [];
			if (!Array.isArray(value)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_ARRAY"));
			} else {
				errors = value.reduce(function(errors, value, i) {
					return errors.concat(
						Validators.Network(value, path.concat([i]))
					);
				}, errors);
			}
			return errors;
		},
	
		/**
		 * 0.2
		 * 
		 * This is an in-line specialization of NumberType
		 * with the following constraints:
		 * 
		 * - minimum: 0.1
		 * - format: double
		 */
		inlineNumberType_2198049c676f86df910c1952accf87e1: function(value, path) {
			var errors = [];
			path = path || [];
			if (isNaN(value)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_NUMBER"));
			} else {
				if (value < 0.1) {
					errors.push(new RAMLError(path, context, "NUMBER_MIN", {"value":0.1}));
				}
			}
			return errors;
		},
	
		/**
		 * 512
		 * 
		 * This is an in-line specialization of NumberType
		 * with the following constraints:
		 * 
		 * - minimum: 32
		 * - format: double
		 */
		inlineNumberType_5fc3df80503e193197ced2bef998de82: function(value, path) {
			var errors = [];
			path = path || [];
			if (isNaN(value)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_NUMBER"));
			} else {
				if (value < 32) {
					errors.push(new RAMLError(path, context, "NUMBER_MIN", {"value":32}));
				}
			}
			return errors;
		},
	
		/**
		 * 50
		 * 
		 * This is an in-line specialization of NumberType
		 * with the following constraints:
		 * 
		 * - minimum: 10
		 * - format: double
		 */
		inlineNumberType_6914d15a748f523af0fc18474685aa42: function(value, path) {
			var errors = [];
			path = path || [];
			if (isNaN(value)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_NUMBER"));
			} else {
				if (value < 10) {
					errors.push(new RAMLError(path, context, "NUMBER_MIN", {"value":10}));
				}
			}
			return errors;
		},
	
		/**
		 * 0
		 * 
		 * This is an in-line specialization of IntegerType
		 * with the following constraints:
		 * 
		 * - format: int64
		 * - minimum: 0
		 */
		inlineIntegerType_b6d3b04861cdc2c21823de4e53983492: function(value, path) {
			var errors = [];
			path = path || [];
			if (isNaN(value) || (value % 1 !== 0)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_INTEGER"));
			} else {
				if (!((value % 1 === 0))) {
					errors.push(new RAMLError(path, context, "NUMBER_TYPE", {"type":"int64"}));
				}
				if (value < 0) {
					errors.push(new RAMLError(path, context, "NUMBER_MIN", {"value":0}));
				}
			}
			return errors;
		},
	
		/**
		 * "some value"
		 * 
		 * This is an in-line specialization of Name
		 * with the following constraints:
		 * 
		 * - pattern: ^[a-z0-9]([-a-z0-9]*[a-z0-9])?$
		 * - maxLength: 63
		 * - minLength: 1
		 */
		inlineName_e959b65d3a390d46cacd062be5baabe7: function(value, path) {
			var errors = [];
			path = path || [];
			if (typeof value != "string") {
				errors.push(new RAMLError(path, context, "TYPE_NOT_STRING"));
			} else {
				if (!context.REGEX[3].exec(value)) {
					errors.push(new RAMLError(path, context, "STRING_PATTERN", {"pattern":"^[a-z0-9]([-a-z0-9]*[a-z0-9])?$"}));
				}
				if (value.length > 63) {
					errors.push(new RAMLError(path, context, "LENGTH_MAX", {"value":63}));
				}
				if (value.length < 1) {
					errors.push(new RAMLError(path, context, "LENGTH_MIN", {"value":1}));
				}
			}
			return errors;
		},
	
		/**
		 * [
		 *   {
		 *     "name": "some value",
		 *     "containerPort": 1,
		 *     "hostPort": 1,
		 *     "protocol": [
		 *       "some value"
		 *     ],
		 *     "labels": {},
		 *     "networkNames": [
		 *       "some value"
		 *     ]
		 *   }
		 * ]
		 * 
		 * This is an in-line specialization of Endpoint
		 * with the following constraints:
		 * 
		 * - items: Endpoint
		 */
		inlineEndpoint_c16b318f4fe8b85b2a89ed06127bb779: function(value, path) {
			var errors = [];
			path = path || [];
			if (!Array.isArray(value)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_ARRAY"));
			} else {
				errors = value.reduce(function(errors, value, i) {
					return errors.concat(
						Validators.Endpoint(value, path.concat([i]))
					);
				}, errors);
			}
			return errors;
		},
	
		/**
		 * [
		 *   {
		 *     "name": "some value",
		 *     "mountPath": "some value",
		 *     "readOnly": true
		 *   }
		 * ]
		 * 
		 * This is an in-line specialization of VolumeMount
		 * with the following constraints:
		 * 
		 * - items: VolumeMount
		 */
		inlineVolumeMount_c16b318f4fe8b85b2a89ed06127bb779: function(value, path) {
			var errors = [];
			path = path || [];
			if (!Array.isArray(value)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_ARRAY"));
			} else {
				errors = value.reduce(function(errors, value, i) {
					return errors.concat(
						Validators.VolumeMount(value, path.concat([i]))
					);
				}, errors);
			}
			return errors;
		},
	
		/**
		 * [
		 *   {
		 *     "uri": "http://download.me/file.tgz",
		 *     "extract": true,
		 *     "executable": true,
		 *     "cache": false
		 *   }
		 * ]
		 * 
		 * This is an in-line specialization of Artifact
		 * with the following constraints:
		 * 
		 * - items: Artifact
		 */
		inlineArtifact_c16b318f4fe8b85b2a89ed06127bb779: function(value, path) {
			var errors = [];
			path = path || [];
			if (!Array.isArray(value)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_ARRAY"));
			} else {
				errors = value.reduce(function(errors, value, i) {
					return errors.concat(
						Validators.Artifact(value, path.concat([i]))
					);
				}, errors);
			}
			return errors;
		},
	
		/**
		 * true
		 * 
		 * This is an in-line specialization of BooleanType
		 * with the following constraints:
		 * 
		 * 
		 */
		inlineBooleanType_d41d8cd98f00b204e9800998ecf8427e: function(value, path) {
			var errors = [];
			path = path || [];
			if ((value !== false) && (value !== true)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_BOOLEAN"));
			} else {
			}
			return errors;
		},
	
		/**
		 * "some value"
		 * 
		 * This is an in-line specialization of StringType
		 * with the following constraints:
		 * 
		 * - minLength: 1
		 */
		inlineStringType_8cef7a2a976678a19a8d836c3a53c236: function(value, path) {
			var errors = [];
			path = path || [];
			if (typeof value != "string") {
				errors.push(new RAMLError(path, context, "TYPE_NOT_STRING"));
			} else {
				if (value.length < 1) {
					errors.push(new RAMLError(path, context, "LENGTH_MIN", {"value":1}));
				}
			}
			return errors;
		},
	
		/**
		 * "some value"
		 * 
		 * This is an in-line specialization of NetworkName
		 * with the following constraints:
		 * 
		 * - pattern: ^[a-z0-9]([-a-z0-9_]*[a-z0-9])?$
		 * - maxLength: 63
		 * - minLength: 1
		 */
		inlineNetworkName_d5930fd0fc0461b8008a443d59699e32: function(value, path) {
			var errors = [];
			path = path || [];
			if (typeof value != "string") {
				errors.push(new RAMLError(path, context, "TYPE_NOT_STRING"));
			} else {
				if (!context.REGEX[4].exec(value)) {
					errors.push(new RAMLError(path, context, "STRING_PATTERN", {"pattern":"^[a-z0-9]([-a-z0-9_]*[a-z0-9])?$"}));
				}
				if (value.length > 63) {
					errors.push(new RAMLError(path, context, "LENGTH_MAX", {"value":63}));
				}
				if (value.length < 1) {
					errors.push(new RAMLError(path, context, "LENGTH_MIN", {"value":1}));
				}
			}
			return errors;
		},
	
		/**
		 * "container"
		 * 
		 * This is an in-line specialization of NetworkMode
		 * with the following constraints:
		 * 
		 * - enum: container,container/bridge,host
		 */
		inlineNetworkMode_4cccbb4c3c88d1078bf95c85c1818632: function(value, path) {
			var errors = [];
			path = path || [];
			if (typeof value != "string") {
				errors.push(new RAMLError(path, context, "TYPE_NOT_STRING"));
			} else {
				if (context.ENUMS[4].indexOf(value.toLowerCase()) === -1) {
					errors.push(new RAMLError(path, context, "ENUM", {"values":"container, container/bridge, host"}));
				}
			}
			return errors;
		},
	
		/**
		 * 0.1
		 * 
		 * This is an in-line specialization of NumberType
		 * with the following constraints:
		 * 
		 * - minimum: 0.001
		 * - format: double
		 */
		inlineNumberType_5471becca47d41cedb309da969544f87: function(value, path) {
			var errors = [];
			path = path || [];
			if (isNaN(value)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_NUMBER"));
			} else {
				if (value < 0.001) {
					errors.push(new RAMLError(path, context, "NUMBER_MIN", {"value":0.001}));
				}
			}
			return errors;
		},
	
		/**
		 * 0.2
		 * 
		 * This is an in-line specialization of NumberType
		 * with the following constraints:
		 * 
		 * - minimum: 0
		 * - format: double
		 */
		inlineNumberType_fa1b78972b7b05a3129cc02bb09d01d2: function(value, path) {
			var errors = [];
			path = path || [];
			if (isNaN(value)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_NUMBER"));
			} else {
				if (value < 0) {
					errors.push(new RAMLError(path, context, "NUMBER_MIN", {"value":0}));
				}
			}
			return errors;
		},
	
		/**
		 * "mesosphere/marathon:1.3.0"
		 * 
		 * This is an in-line specialization of Path
		 * with the following constraints:
		 * 
		 * - minLength: 1
		 * - maxLength: 1024
		 */
		inlinePath_293be7de6c009b1ca492ed5c5b0eafa1: function(value, path) {
			var errors = [];
			path = path || [];
			if (typeof value != "string") {
				errors.push(new RAMLError(path, context, "TYPE_NOT_STRING"));
			} else {
				if (value.length < 1) {
					errors.push(new RAMLError(path, context, "LENGTH_MIN", {"value":1}));
				}
				if (value.length > 1024) {
					errors.push(new RAMLError(path, context, "LENGTH_MAX", {"value":1024}));
				}
			}
			return errors;
		},
	
		/**
		 * 1
		 * 
		 * This is an in-line specialization of Port
		 * with the following constraints:
		 * 
		 * - minimum: 1
		 * - maximum: 65535
		 * - format: int32
		 */
		inlinePort_8a1fd38080fb8d93e0f812222c697fb9: function(value, path) {
			var errors = [];
			path = path || [];
			if (isNaN(value) || (value % 1 !== 0)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_INTEGER"));
			} else {
				if (value < 1) {
					errors.push(new RAMLError(path, context, "NUMBER_MIN", {"value":1}));
				}
				if (value > 65535) {
					errors.push(new RAMLError(path, context, "NUMBER_MAX", {"value":65535}));
				}
				if (!((value % 1 === 0) && (value >= -2147483648) && (value <= 2147483647))) {
					errors.push(new RAMLError(path, context, "NUMBER_TYPE", {"type":"int32"}));
				}
			}
			return errors;
		},
	
		/**
		 * 1
		 * 
		 * This is an in-line specialization of AnyPort
		 * with the following constraints:
		 * 
		 * - minimum: 0
		 * - maximum: 65535
		 * - format: int32
		 */
		inlineAnyPort_f0c659cc7c5ba17b44d6bdf5278be7bf: function(value, path) {
			var errors = [];
			path = path || [];
			if (isNaN(value) || (value % 1 !== 0)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_INTEGER"));
			} else {
				if (value < 0) {
					errors.push(new RAMLError(path, context, "NUMBER_MIN", {"value":0}));
				}
				if (value > 65535) {
					errors.push(new RAMLError(path, context, "NUMBER_MAX", {"value":65535}));
				}
				if (!((value % 1 === 0) && (value >= -2147483648) && (value <= 2147483647))) {
					errors.push(new RAMLError(path, context, "NUMBER_TYPE", {"type":"int32"}));
				}
			}
			return errors;
		},
	
		/**
		 * [
		 *   "some value"
		 * ]
		 * 
		 * This is an in-line specialization of Name
		 * with the following constraints:
		 * 
		 * - items: Name
		 * - minItems: 1
		 */
		inlineName_5023763c58cb378ebf05c94ca2bf36a7: function(value, path) {
			var errors = [];
			path = path || [];
			if (!Array.isArray(value)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_ARRAY"));
			} else {
				errors = value.reduce(function(errors, value, i) {
					return errors.concat(
						Validators.Name(value, path.concat([i]))
					);
				}, errors);
				if (value.length < 1) {
					errors.push(new RAMLError(path, context, "ITEMS_MIN", {"value":1}));
				}
			}
			return errors;
		},
	
		/**
		 * [
		 *   "some value"
		 * ]
		 * 
		 * This is an in-line specialization of Name
		 * with the following constraints:
		 * 
		 * - items: Name
		 */
		inlineName_c16b318f4fe8b85b2a89ed06127bb779: function(value, path) {
			var errors = [];
			path = path || [];
			if (!Array.isArray(value)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_ARRAY"));
			} else {
				errors = value.reduce(function(errors, value, i) {
					return errors.concat(
						Validators.Name(value, path.concat([i]))
					);
				}, errors);
			}
			return errors;
		},
	
		/**
		 * "HTTP"
		 * 
		 * This is an in-line specialization of HttpScheme
		 * with the following constraints:
		 * 
		 * - enum: HTTP,HTTPS
		 */
		inlineHttpScheme_9daecb70f32aeec3a0f8567d3168714f: function(value, path) {
			var errors = [];
			path = path || [];
			if (typeof value != "string") {
				errors.push(new RAMLError(path, context, "TYPE_NOT_STRING"));
			} else {
				if (context.ENUMS[6].indexOf(value.toLowerCase()) === -1) {
					errors.push(new RAMLError(path, context, "ENUM", {"values":"http, https"}));
				}
			}
			return errors;
		},
	
		/**
		 * [
		 *   [
		 *     "some value"
		 *   ]
		 * ]
		 * 
		 * This is an in-line specialization of VolumeConstraint
		 * with the following constraints:
		 * 
		 * - uniqueItems: true
		 * - items: VolumeConstraint
		 */
		inlineVolumeConstraint_3b1f04aede5a1e865648311c626a62e2: function(value, path) {
			var errors = [];
			path = path || [];
			if (!Array.isArray(value)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_ARRAY"));
			} else {
				if ((function() {
					var valuesSoFar = Object.create(null);
					for (var i = 0; i < value.length; ++i) {
						var val = JSON.stringify(value[i]);
						if (val in valuesSoFar) {
							return true;
						}
						valuesSoFar[val] = true;
					}
					return false;
				})()) {
					errors.push(new RAMLError(path, context, "ITEMS_UNIQUE"));
				}
				errors = value.reduce(function(errors, value, i) {
					return errors.concat(
						Validators.VolumeConstraint(value, path.concat([i]))
					);
				}, errors);
			}
			return errors;
		},
	
	};
	
	var Validators = {
		/**
		 * "some value"
		 */
		PodScalingPolicyType: function(value, path) {
			var errors = [];
			path = path || [];
			if (typeof value != "string") {
				errors.push(new RAMLError(path, context, "TYPE_NOT_STRING"));
			} else {
				if (context.ENUMS[0].indexOf(value.toLowerCase()) === -1) {
					errors.push(new RAMLError(path, context, "ENUM", {"values":"fixed"}));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "kind": "some value"
		 * }
		 */
		PodScalingPolicy: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.kind == null) {
					errors.push(new RAMLError(path.concat(['kind']), context, "PROP_IS_MISSING", {name: 'kind'}));
				} else {
					errors = errors.concat(Validators.PodScalingPolicyType(value.kind, path.concat(['kind'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "instances": 1,
		 *   "kind": "some value"
		 * }
		 */
		FixedPodScalingPolicy: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.kind == null) {
					errors.push(new RAMLError(path.concat(['kind']), context, "PROP_IS_MISSING", {name: 'kind'}));
				} else {
					errors = errors.concat(Validators.PodScalingPolicyType(value.kind, path.concat(['kind'])));
				}
				if (value.instances == null) {
					errors.push(new RAMLError(path.concat(['instances']), context, "PROP_IS_MISSING", {name: 'instances'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineIntegerType_1da39b1015745f6788e8467b4b016d08(value.instances, path.concat(['instances'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "backoff": 1,
		 *   "backoffFactor": 1.15,
		 *   "maxLaunchDelay": 300
		 * }
		 */
		PodSchedulingBackoffStrategy: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.backoff != null) {
					errors = errors.concat(PrivateValidators.inlineNumberType_57bfd98cf72e8ce725369576269362ad(value.backoff, path.concat(['backoff'])));
				}
				if (value.backoffFactor != null) {
					errors = errors.concat(PrivateValidators.inlineNumberType_57bfd98cf72e8ce725369576269362ad(value.backoffFactor, path.concat(['backoffFactor'])));
				}
				if (value.maxLaunchDelay != null) {
					errors = errors.concat(PrivateValidators.inlineNumberType_57bfd98cf72e8ce725369576269362ad(value.maxLaunchDelay, path.concat(['maxLaunchDelay'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "minimumHealthCapacity": 1,
		 *   "maximumOverCapacity": 1
		 * }
		 */
		PodUpgradeStrategy: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.minimumHealthCapacity != null) {
					errors = errors.concat(PrivateValidators.inlineNumberType_755aef13143071d7e09ebe3f57078b7f(value.minimumHealthCapacity, path.concat(['minimumHealthCapacity'])));
				}
				if (value.maximumOverCapacity != null) {
					errors = errors.concat(PrivateValidators.inlineNumberType_755aef13143071d7e09ebe3f57078b7f(value.maximumOverCapacity, path.concat(['maximumOverCapacity'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "constraints": [
		 *     {
		 *       "fieldName": "hostname",
		 *       "operator": "UNIQUE"
		 *     }
		 *   ],
		 *   "acceptedResourceRoles": [
		 *     "public-facing"
		 *   ]
		 * }
		 */
		PodPlacementPolicy: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.constraints != null) {
					errors = errors.concat(PrivateValidators.inlineConstraint_3b1f04aede5a1e865648311c626a62e2(value.constraints, path.concat(['constraints'])));
				}
				if (value.acceptedResourceRoles != null) {
					errors = errors.concat(PrivateValidators.inlineStringType_c16b318f4fe8b85b2a89ed06127bb779(value.acceptedResourceRoles, path.concat(['acceptedResourceRoles'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "backoff": {
		 *     "backoff": 1,
		 *     "backoffFactor": 1.15,
		 *     "maxLaunchDelay": 300
		 *   },
		 *   "upgrade": {
		 *     "minimumHealthCapacity": 1,
		 *     "maximumOverCapacity": 1
		 *   },
		 *   "placement": {
		 *     "constraints": [
		 *       {
		 *         "fieldName": "hostname",
		 *         "operator": "UNIQUE"
		 *       }
		 *     ],
		 *     "acceptedResourceRoles": [
		 *       "public-facing"
		 *     ]
		 *   },
		 *   "killSelection": "YOUNGEST_FIRST",
		 *   "unreachableStrategy": "disabled"
		 * }
		 */
		PodSchedulingPolicy: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.backoff != null) {
					errors = errors.concat(Validators.PodSchedulingBackoffStrategy(value.backoff, path.concat(['backoff'])));
				}
				if (value.upgrade != null) {
					errors = errors.concat(Validators.PodUpgradeStrategy(value.upgrade, path.concat(['upgrade'])));
				}
				if (value.placement != null) {
					errors = errors.concat(Validators.PodPlacementPolicy(value.placement, path.concat(['placement'])));
				}
				if (value.killSelection != null) {
					errors = errors.concat(Validators.KillSelection(value.killSelection, path.concat(['killSelection'])));
				}
				if (value.unreachableStrategy != null) {
					errors = errors.concat(Validators.UnreachableStrategy(value.unreachableStrategy, path.concat(['unreachableStrategy'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "id": "/ops/audit",
		 *   "labels": {},
		 *   "version": "some value",
		 *   "user": "some value",
		 *   "environment": {},
		 *   "containers": [
		 *     {
		 *       "name": "some value",
		 *       "exec": {
		 *         "command": {
		 *           "shell": "some value"
		 *         },
		 *         "overrideEntrypoint": true
		 *       },
		 *       "resources": {
		 *         "cpus": 0.1,
		 *         "mem": 512,
		 *         "disk": 0.2,
		 *         "gpus": 1
		 *       },
		 *       "endpoints": [
		 *         {
		 *           "name": "some value",
		 *           "containerPort": 1,
		 *           "hostPort": 1,
		 *           "protocol": [
		 *             "some value"
		 *           ],
		 *           "labels": {},
		 *           "networkNames": [
		 *             "some value"
		 *           ]
		 *         }
		 *       ],
		 *       "image": {
		 *         "kind": "some value",
		 *         "id": "mesosphere/marathon:1.3.0",
		 *         "pullConfig": {
		 *           "secret": "some value"
		 *         },
		 *         "forcePull": true,
		 *         "labels": {}
		 *       },
		 *       "environment": {},
		 *       "user": "some value",
		 *       "healthCheck": {
		 *         "http": {
		 *           "endpoint": "some value",
		 *           "path": "some value",
		 *           "scheme": "HTTP"
		 *         },
		 *         "tcp": {
		 *           "endpoint": "some value"
		 *         },
		 *         "exec": {
		 *           "command": {
		 *             "shell": "some value"
		 *           }
		 *         },
		 *         "gracePeriodSeconds": 300,
		 *         "intervalSeconds": 60,
		 *         "maxConsecutiveFailures": 3,
		 *         "timeoutSeconds": 20,
		 *         "delaySeconds": 15
		 *       },
		 *       "check": {
		 *         "http": {
		 *           "endpoint": "some value",
		 *           "path": "some value",
		 *           "scheme": "HTTP"
		 *         },
		 *         "tcp": {
		 *           "endpoint": "some value"
		 *         },
		 *         "exec": {
		 *           "command": {
		 *             "shell": "some value"
		 *           }
		 *         },
		 *         "intervalSeconds": 60,
		 *         "timeoutSeconds": 20,
		 *         "delaySeconds": 15
		 *       },
		 *       "volumeMounts": [
		 *         {
		 *           "name": "some value",
		 *           "mountPath": "some value",
		 *           "readOnly": true
		 *         }
		 *       ],
		 *       "artifacts": [
		 *         {
		 *           "uri": "http://download.me/file.tgz",
		 *           "extract": true,
		 *           "executable": true,
		 *           "cache": false
		 *         }
		 *       ],
		 *       "labels": {},
		 *       "lifecycle": {
		 *         "killGracePeriodSeconds": 1
		 *       },
		 *       "tty": true
		 *     }
		 *   ],
		 *   "secrets": {},
		 *   "volumes": [
		 *     {
		 *       "name": "some value",
		 *       "persistent": {
		 *         "type": "some value",
		 *         "size": 1,
		 *         "maxSize": 1,
		 *         "profileName": "some value",
		 *         "constraints": [
		 *           [
		 *             "some value"
		 *           ]
		 *         ]
		 *       }
		 *     }
		 *   ],
		 *   "networks": [
		 *     {
		 *       "name": "some value",
		 *       "mode": "container",
		 *       "labels": {}
		 *     }
		 *   ],
		 *   "scaling": {
		 *     "kind": "some value"
		 *   },
		 *   "scheduling": {
		 *     "backoff": {
		 *       "backoff": 1,
		 *       "backoffFactor": 1.15,
		 *       "maxLaunchDelay": 300
		 *     },
		 *     "upgrade": {
		 *       "minimumHealthCapacity": 1,
		 *       "maximumOverCapacity": 1
		 *     },
		 *     "placement": {
		 *       "constraints": [
		 *         {
		 *           "fieldName": "hostname",
		 *           "operator": "UNIQUE"
		 *         }
		 *       ],
		 *       "acceptedResourceRoles": [
		 *         "public-facing"
		 *       ]
		 *     },
		 *     "killSelection": "YOUNGEST_FIRST",
		 *     "unreachableStrategy": "disabled"
		 *   },
		 *   "executorResources": {
		 *     "cpus": 0.2,
		 *     "mem": 512,
		 *     "disk": 50
		 *   }
		 * }
		 */
		Pod: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.id == null) {
					errors.push(new RAMLError(path.concat(['id']), context, "PROP_IS_MISSING", {name: 'id'}));
				} else {
					errors = errors.concat(Validators.PathId(value.id, path.concat(['id'])));
				}
				if (value.labels != null) {
					errors = errors.concat(Validators.KVLabels(value.labels, path.concat(['labels'])));
				}
				if (value.version != null) {
					errors = errors.concat(PrivateValidators.inlineDateTimeType_d41d8cd98f00b204e9800998ecf8427e(value.version, path.concat(['version'])));
				}
				if (value.user != null) {
					errors = errors.concat(PrivateValidators.inlineStringType_d41d8cd98f00b204e9800998ecf8427e(value.user, path.concat(['user'])));
				}
				if (value.environment != null) {
					errors = errors.concat(Validators.EnvVars(value.environment, path.concat(['environment'])));
				}
				if (value.containers == null) {
					errors.push(new RAMLError(path.concat(['containers']), context, "PROP_IS_MISSING", {name: 'containers'}));
				} else {
					errors = errors.concat(PrivateValidators.inlinePodContainer_5023763c58cb378ebf05c94ca2bf36a7(value.containers, path.concat(['containers'])));
				}
				if (value.secrets != null) {
					errors = errors.concat(Validators.Secrets(value.secrets, path.concat(['secrets'])));
				}
				if (value.volumes != null) {
					errors = errors.concat(PrivateValidators.inlinePodVolume_c16b318f4fe8b85b2a89ed06127bb779(value.volumes, path.concat(['volumes'])));
				}
				if (value.networks != null) {
					errors = errors.concat(PrivateValidators.inlineNetwork_c16b318f4fe8b85b2a89ed06127bb779(value.networks, path.concat(['networks'])));
				}
				if (value.scaling != null) {
					errors = errors.concat(Validators.PodScalingPolicy(value.scaling, path.concat(['scaling'])));
				}
				if (value.scheduling != null) {
					errors = errors.concat(Validators.PodSchedulingPolicy(value.scheduling, path.concat(['scheduling'])));
				}
				if (value.executorResources != null) {
					errors = errors.concat(Validators.ExecutorResources(value.executorResources, path.concat(['executorResources'])));
				}
			}
			return errors;
		},
	
		/**
		 * "YOUNGEST_FIRST"
		 */
		KillSelection: function(value, path) {
			var errors = [];
			path = path || [];
			if (typeof value != "string") {
				errors.push(new RAMLError(path, context, "TYPE_NOT_STRING"));
			} else {
				if (context.ENUMS[1].indexOf(value.toLowerCase()) === -1) {
					errors.push(new RAMLError(path, context, "ENUM", {"values":"youngest_first, oldest_first"}));
				}
			}
			return errors;
		},
	
		/**
		 * "disabled"
		 */
		UnreachableStrategy: function(value, path) {
			var errors = [];
			path = path || [];
			errors = errors.concat([
				Validators.UnreachableDisabled(value, path),
				Validators.UnreachableEnabled(value, path),
			].sort(function(a, b) {
				return a.length - b.length;
			})[0]);
			return errors;
		},
	
		/**
		 * "/ops/audit"
		 */
		PathId: function(value, path) {
			var errors = [];
			path = path || [];
			if (typeof value != "string") {
				errors.push(new RAMLError(path, context, "TYPE_NOT_STRING"));
			} else {
				if (!context.REGEX[0].exec(value)) {
					errors.push(new RAMLError(path, context, "STRING_PATTERN", {"pattern":"^(\\/?((\\.\\.)|(([a-z0-9]|[a-z0-9][a-z0-9\\-]*[a-z0-9])\\.)*([a-z0-9]|[a-z0-9][a-z0-9\\-]*[a-z0-9]))?($|\\/))+$"}));
				}
				if (value.length < 1) {
					errors.push(new RAMLError(path, context, "LENGTH_MIN", {"value":1}));
				}
			}
			return errors;
		},
	
		/**
		 * {}
		 */
		KVLabels: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				var matched = [];
				var props = Object.keys(value);
				var regexProps = props;
				matched = regexProps.filter(function(key) {
					return context.REGEX[1].exec(key);
				});
				errors = matched.reduce(function(errors, property) {
					return errors.concat(PrivateValidators.inlineStringType_d41d8cd98f00b204e9800998ecf8427e(value[property], path.concat([property])));
				}, errors);
			}
			return errors;
		},
	
		/**
		 * {}
		 */
		EnvVars: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				var matched = [];
				var props = Object.keys(value);
				var regexProps = props;
				matched = regexProps.filter(function(key) {
					return context.REGEX[2].exec(key);
				});
				errors = matched.reduce(function(errors, property) {
					return errors.concat(Validators.EnvVarValueOrSecret(value[property], path.concat([property])));
				}, errors);
			}
			return errors;
		},
	
		/**
		 * {}
		 */
		Secrets: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				var matched = [];
				var props = Object.keys(value);
				var regexProps = props;
				matched = regexProps.filter(function(key) {
					return context.REGEX[1].exec(key);
				});
				errors = matched.reduce(function(errors, property) {
					return errors.concat(Validators.SecretDef(value[property], path.concat([property])));
				}, errors);
			}
			return errors;
		},
	
		/**
		 * {
		 *   "cpus": 0.2,
		 *   "mem": 512,
		 *   "disk": 50
		 * }
		 */
		ExecutorResources: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.cpus != null) {
					errors = errors.concat(PrivateValidators.inlineNumberType_2198049c676f86df910c1952accf87e1(value.cpus, path.concat(['cpus'])));
				}
				if (value.mem != null) {
					errors = errors.concat(PrivateValidators.inlineNumberType_5fc3df80503e193197ced2bef998de82(value.mem, path.concat(['mem'])));
				}
				if (value.disk != null) {
					errors = errors.concat(PrivateValidators.inlineNumberType_6914d15a748f523af0fc18474685aa42(value.disk, path.concat(['disk'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "fieldName": "hostname",
		 *   "operator": "UNIQUE"
		 * }
		 */
		Constraint: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.fieldName == null) {
					errors.push(new RAMLError(path.concat(['fieldName']), context, "PROP_IS_MISSING", {name: 'fieldName'}));
				} else {
					errors = errors.concat(Validators.StringType(value.fieldName, path.concat(['fieldName'])));
				}
				if (value.operator == null) {
					errors.push(new RAMLError(path.concat(['operator']), context, "PROP_IS_MISSING", {name: 'operator'}));
				} else {
					errors = errors.concat(Validators.ConstraintOperator(value.operator, path.concat(['operator'])));
				}
				if (value.value != null) {
					errors = errors.concat(Validators.StringType(value.value, path.concat(['value'])));
				}
			}
			return errors;
		},
	
		/**
		 * "some value"
		 */
		StringType: function(value, path) {
			var errors = [];
			path = path || [];
			if (typeof value != "string") {
				errors.push(new RAMLError(path, context, "TYPE_NOT_STRING"));
			} else {
			}
			return errors;
		},
	
		/**
		 * "disabled"
		 */
		UnreachableDisabled: function(value, path) {
			var errors = [];
			path = path || [];
			if (typeof value != "string") {
				errors.push(new RAMLError(path, context, "TYPE_NOT_STRING"));
			} else {
				if (context.ENUMS[2].indexOf(value.toLowerCase()) === -1) {
					errors.push(new RAMLError(path, context, "ENUM", {"values":"disabled"}));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "inactiveAfterSeconds": 0,
		 *   "expungeAfterSeconds": 0
		 * }
		 */
		UnreachableEnabled: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.inactiveAfterSeconds != null) {
					errors = errors.concat(PrivateValidators.inlineIntegerType_b6d3b04861cdc2c21823de4e53983492(value.inactiveAfterSeconds, path.concat(['inactiveAfterSeconds'])));
				}
				if (value.expungeAfterSeconds != null) {
					errors = errors.concat(PrivateValidators.inlineIntegerType_b6d3b04861cdc2c21823de4e53983492(value.expungeAfterSeconds, path.concat(['expungeAfterSeconds'])));
				}
			}
			return errors;
		},
	
		/**
		 * "some value"
		 */
		EnvVarValueOrSecret: function(value, path) {
			var errors = [];
			path = path || [];
			errors = errors.concat([
				Validators.EnvVarValue(value, path),
				Validators.EnvVarSecret(value, path),
			].sort(function(a, b) {
				return a.length - b.length;
			})[0]);
			return errors;
		},
	
		/**
		 * {
		 *   "name": "some value",
		 *   "exec": {
		 *     "command": {
		 *       "shell": "some value"
		 *     },
		 *     "overrideEntrypoint": true
		 *   },
		 *   "resources": {
		 *     "cpus": 0.1,
		 *     "mem": 512,
		 *     "disk": 0.2,
		 *     "gpus": 1
		 *   },
		 *   "endpoints": [
		 *     {
		 *       "name": "some value",
		 *       "containerPort": 1,
		 *       "hostPort": 1,
		 *       "protocol": [
		 *         "some value"
		 *       ],
		 *       "labels": {},
		 *       "networkNames": [
		 *         "some value"
		 *       ]
		 *     }
		 *   ],
		 *   "image": {
		 *     "kind": "some value",
		 *     "id": "mesosphere/marathon:1.3.0",
		 *     "pullConfig": {
		 *       "secret": "some value"
		 *     },
		 *     "forcePull": true,
		 *     "labels": {}
		 *   },
		 *   "environment": {},
		 *   "user": "some value",
		 *   "healthCheck": {
		 *     "http": {
		 *       "endpoint": "some value",
		 *       "path": "some value",
		 *       "scheme": "HTTP"
		 *     },
		 *     "tcp": {
		 *       "endpoint": "some value"
		 *     },
		 *     "exec": {
		 *       "command": {
		 *         "shell": "some value"
		 *       }
		 *     },
		 *     "gracePeriodSeconds": 300,
		 *     "intervalSeconds": 60,
		 *     "maxConsecutiveFailures": 3,
		 *     "timeoutSeconds": 20,
		 *     "delaySeconds": 15
		 *   },
		 *   "check": {
		 *     "http": {
		 *       "endpoint": "some value",
		 *       "path": "some value",
		 *       "scheme": "HTTP"
		 *     },
		 *     "tcp": {
		 *       "endpoint": "some value"
		 *     },
		 *     "exec": {
		 *       "command": {
		 *         "shell": "some value"
		 *       }
		 *     },
		 *     "intervalSeconds": 60,
		 *     "timeoutSeconds": 20,
		 *     "delaySeconds": 15
		 *   },
		 *   "volumeMounts": [
		 *     {
		 *       "name": "some value",
		 *       "mountPath": "some value",
		 *       "readOnly": true
		 *     }
		 *   ],
		 *   "artifacts": [
		 *     {
		 *       "uri": "http://download.me/file.tgz",
		 *       "extract": true,
		 *       "executable": true,
		 *       "cache": false
		 *     }
		 *   ],
		 *   "labels": {},
		 *   "lifecycle": {
		 *     "killGracePeriodSeconds": 1
		 *   },
		 *   "tty": true
		 * }
		 */
		PodContainer: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.name == null) {
					errors.push(new RAMLError(path.concat(['name']), context, "PROP_IS_MISSING", {name: 'name'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineName_e959b65d3a390d46cacd062be5baabe7(value.name, path.concat(['name'])));
				}
				if (value.exec != null) {
					errors = errors.concat(Validators.MesosExec(value.exec, path.concat(['exec'])));
				}
				if (value.resources == null) {
					errors.push(new RAMLError(path.concat(['resources']), context, "PROP_IS_MISSING", {name: 'resources'}));
				} else {
					errors = errors.concat(Validators.Resources(value.resources, path.concat(['resources'])));
				}
				if (value.endpoints != null) {
					errors = errors.concat(PrivateValidators.inlineEndpoint_c16b318f4fe8b85b2a89ed06127bb779(value.endpoints, path.concat(['endpoints'])));
				}
				if (value.image != null) {
					errors = errors.concat(Validators.Image(value.image, path.concat(['image'])));
				}
				if (value.environment != null) {
					errors = errors.concat(Validators.EnvVars(value.environment, path.concat(['environment'])));
				}
				if (value.user != null) {
					errors = errors.concat(PrivateValidators.inlineStringType_d41d8cd98f00b204e9800998ecf8427e(value.user, path.concat(['user'])));
				}
				if (value.healthCheck != null) {
					errors = errors.concat(Validators.HealthCheck(value.healthCheck, path.concat(['healthCheck'])));
				}
				if (value.check != null) {
					errors = errors.concat(Validators.Check(value.check, path.concat(['check'])));
				}
				if (value.volumeMounts != null) {
					errors = errors.concat(PrivateValidators.inlineVolumeMount_c16b318f4fe8b85b2a89ed06127bb779(value.volumeMounts, path.concat(['volumeMounts'])));
				}
				if (value.artifacts != null) {
					errors = errors.concat(PrivateValidators.inlineArtifact_c16b318f4fe8b85b2a89ed06127bb779(value.artifacts, path.concat(['artifacts'])));
				}
				if (value.labels != null) {
					errors = errors.concat(Validators.KVLabels(value.labels, path.concat(['labels'])));
				}
				if (value.lifecycle != null) {
					errors = errors.concat(Validators.Lifecycle(value.lifecycle, path.concat(['lifecycle'])));
				}
				if (value.tty != null) {
					errors = errors.concat(PrivateValidators.inlineBooleanType_d41d8cd98f00b204e9800998ecf8427e(value.tty, path.concat(['tty'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "source": "some value"
		 * }
		 */
		SecretDef: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.source == null) {
					errors.push(new RAMLError(path.concat(['source']), context, "PROP_IS_MISSING", {name: 'source'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineStringType_8cef7a2a976678a19a8d836c3a53c236(value.source, path.concat(['source'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "name": "some value",
		 *   "persistent": {
		 *     "type": "some value",
		 *     "size": 1,
		 *     "maxSize": 1,
		 *     "profileName": "some value",
		 *     "constraints": [
		 *       [
		 *         "some value"
		 *       ]
		 *     ]
		 *   }
		 * }
		 */
		PodVolume: function(value, path) {
			var errors = [];
			path = path || [];
			errors = errors.concat([
				Validators.PodPersistentVolume(value, path),
				Validators.PodSecretVolume(value, path),
				Validators.PodHostVolume(value, path),
				Validators.PodEphemeralVolume(value, path),
			].sort(function(a, b) {
				return a.length - b.length;
			})[0]);
			return errors;
		},
	
		/**
		 * {
		 *   "name": "some value",
		 *   "mode": "container",
		 *   "labels": {}
		 * }
		 */
		Network: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.name != null) {
					errors = errors.concat(PrivateValidators.inlineNetworkName_d5930fd0fc0461b8008a443d59699e32(value.name, path.concat(['name'])));
				}
				if (value.mode != null) {
					errors = errors.concat(PrivateValidators.inlineNetworkMode_4cccbb4c3c88d1078bf95c85c1818632(value.mode, path.concat(['mode'])));
				}
				if (value.labels != null) {
					errors = errors.concat(Validators.KVLabels(value.labels, path.concat(['labels'])));
				}
			}
			return errors;
		},
	
		/**
		 * "some value"
		 */
		ConstraintOperator: function(value, path) {
			var errors = [];
			path = path || [];
			if (typeof value != "string") {
				errors.push(new RAMLError(path, context, "TYPE_NOT_STRING"));
			} else {
				if (context.ENUMS[3].indexOf(value.toLowerCase()) === -1) {
					errors.push(new RAMLError(path, context, "ENUM", {"values":"unique, cluster, group_by, like, unlike, max_per, is"}));
				}
			}
			return errors;
		},
	
		/**
		 * "some value"
		 */
		EnvVarValue: function(value, path) {
			var errors = [];
			path = path || [];
			if (typeof value != "string") {
				errors.push(new RAMLError(path, context, "TYPE_NOT_STRING"));
			} else {
			}
			return errors;
		},
	
		/**
		 * {
		 *   "secret": "some value"
		 * }
		 */
		EnvVarSecret: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.secret == null) {
					errors.push(new RAMLError(path.concat(['secret']), context, "PROP_IS_MISSING", {name: 'secret'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineStringType_d41d8cd98f00b204e9800998ecf8427e(value.secret, path.concat(['secret'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "command": {
		 *     "shell": "some value"
		 *   },
		 *   "overrideEntrypoint": true
		 * }
		 */
		MesosExec: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.command == null) {
					errors.push(new RAMLError(path.concat(['command']), context, "PROP_IS_MISSING", {name: 'command'}));
				} else {
					errors = errors.concat(Validators.MesosCommand(value.command, path.concat(['command'])));
				}
				if (value.overrideEntrypoint != null) {
					errors = errors.concat(PrivateValidators.inlineBooleanType_d41d8cd98f00b204e9800998ecf8427e(value.overrideEntrypoint, path.concat(['overrideEntrypoint'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "cpus": 0.1,
		 *   "mem": 512,
		 *   "disk": 0.2,
		 *   "gpus": 1
		 * }
		 */
		Resources: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.cpus == null) {
					errors.push(new RAMLError(path.concat(['cpus']), context, "PROP_IS_MISSING", {name: 'cpus'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineNumberType_5471becca47d41cedb309da969544f87(value.cpus, path.concat(['cpus'])));
				}
				if (value.mem == null) {
					errors.push(new RAMLError(path.concat(['mem']), context, "PROP_IS_MISSING", {name: 'mem'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineNumberType_5471becca47d41cedb309da969544f87(value.mem, path.concat(['mem'])));
				}
				if (value.disk != null) {
					errors = errors.concat(PrivateValidators.inlineNumberType_fa1b78972b7b05a3129cc02bb09d01d2(value.disk, path.concat(['disk'])));
				}
				if (value.gpus != null) {
					errors = errors.concat(PrivateValidators.inlineIntegerType_1da39b1015745f6788e8467b4b016d08(value.gpus, path.concat(['gpus'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "kind": "some value",
		 *   "id": "mesosphere/marathon:1.3.0",
		 *   "pullConfig": {
		 *     "secret": "some value"
		 *   },
		 *   "forcePull": true,
		 *   "labels": {}
		 * }
		 */
		Image: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.kind == null) {
					errors.push(new RAMLError(path.concat(['kind']), context, "PROP_IS_MISSING", {name: 'kind'}));
				} else {
					errors = errors.concat(Validators.ImageType(value.kind, path.concat(['kind'])));
				}
				if (value.id == null) {
					errors.push(new RAMLError(path.concat(['id']), context, "PROP_IS_MISSING", {name: 'id'}));
				} else {
					errors = errors.concat(PrivateValidators.inlinePath_293be7de6c009b1ca492ed5c5b0eafa1(value.id, path.concat(['id'])));
				}
				if (value.pullConfig != null) {
					errors = errors.concat(Validators.DockerPullConfig(value.pullConfig, path.concat(['pullConfig'])));
				}
				if (value.forcePull != null) {
					errors = errors.concat(PrivateValidators.inlineBooleanType_d41d8cd98f00b204e9800998ecf8427e(value.forcePull, path.concat(['forcePull'])));
				}
				if (value.labels != null) {
					errors = errors.concat(Validators.KVLabels(value.labels, path.concat(['labels'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "http": {
		 *     "endpoint": "some value",
		 *     "path": "some value",
		 *     "scheme": "HTTP"
		 *   },
		 *   "tcp": {
		 *     "endpoint": "some value"
		 *   },
		 *   "exec": {
		 *     "command": {
		 *       "shell": "some value"
		 *     }
		 *   },
		 *   "gracePeriodSeconds": 300,
		 *   "intervalSeconds": 60,
		 *   "maxConsecutiveFailures": 3,
		 *   "timeoutSeconds": 20,
		 *   "delaySeconds": 15
		 * }
		 */
		HealthCheck: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.http != null) {
					errors = errors.concat(Validators.HttpCheck(value.http, path.concat(['http'])));
				}
				if (value.tcp != null) {
					errors = errors.concat(Validators.TcpCheck(value.tcp, path.concat(['tcp'])));
				}
				if (value.exec != null) {
					errors = errors.concat(Validators.CommandCheck(value.exec, path.concat(['exec'])));
				}
				if (value.gracePeriodSeconds != null) {
					errors = errors.concat(PrivateValidators.inlineIntegerType_1da39b1015745f6788e8467b4b016d08(value.gracePeriodSeconds, path.concat(['gracePeriodSeconds'])));
				}
				if (value.intervalSeconds != null) {
					errors = errors.concat(PrivateValidators.inlineIntegerType_1da39b1015745f6788e8467b4b016d08(value.intervalSeconds, path.concat(['intervalSeconds'])));
				}
				if (value.maxConsecutiveFailures != null) {
					errors = errors.concat(PrivateValidators.inlineIntegerType_1da39b1015745f6788e8467b4b016d08(value.maxConsecutiveFailures, path.concat(['maxConsecutiveFailures'])));
				}
				if (value.timeoutSeconds != null) {
					errors = errors.concat(PrivateValidators.inlineIntegerType_1da39b1015745f6788e8467b4b016d08(value.timeoutSeconds, path.concat(['timeoutSeconds'])));
				}
				if (value.delaySeconds != null) {
					errors = errors.concat(PrivateValidators.inlineIntegerType_1da39b1015745f6788e8467b4b016d08(value.delaySeconds, path.concat(['delaySeconds'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "http": {
		 *     "endpoint": "some value",
		 *     "path": "some value",
		 *     "scheme": "HTTP"
		 *   },
		 *   "tcp": {
		 *     "endpoint": "some value"
		 *   },
		 *   "exec": {
		 *     "command": {
		 *       "shell": "some value"
		 *     }
		 *   },
		 *   "intervalSeconds": 60,
		 *   "timeoutSeconds": 20,
		 *   "delaySeconds": 15
		 * }
		 */
		Check: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.http != null) {
					errors = errors.concat(Validators.HttpCheck(value.http, path.concat(['http'])));
				}
				if (value.tcp != null) {
					errors = errors.concat(Validators.TcpCheck(value.tcp, path.concat(['tcp'])));
				}
				if (value.exec != null) {
					errors = errors.concat(Validators.CommandCheck(value.exec, path.concat(['exec'])));
				}
				if (value.intervalSeconds != null) {
					errors = errors.concat(PrivateValidators.inlineIntegerType_1da39b1015745f6788e8467b4b016d08(value.intervalSeconds, path.concat(['intervalSeconds'])));
				}
				if (value.timeoutSeconds != null) {
					errors = errors.concat(PrivateValidators.inlineIntegerType_1da39b1015745f6788e8467b4b016d08(value.timeoutSeconds, path.concat(['timeoutSeconds'])));
				}
				if (value.delaySeconds != null) {
					errors = errors.concat(PrivateValidators.inlineIntegerType_1da39b1015745f6788e8467b4b016d08(value.delaySeconds, path.concat(['delaySeconds'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "killGracePeriodSeconds": 1
		 * }
		 */
		Lifecycle: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.killGracePeriodSeconds != null) {
					errors = errors.concat(PrivateValidators.inlineNumberType_fa1b78972b7b05a3129cc02bb09d01d2(value.killGracePeriodSeconds, path.concat(['killGracePeriodSeconds'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "name": "some value",
		 *   "persistent": {
		 *     "type": "some value",
		 *     "size": 1,
		 *     "maxSize": 1,
		 *     "profileName": "some value",
		 *     "constraints": [
		 *       [
		 *         "some value"
		 *       ]
		 *     ]
		 *   }
		 * }
		 */
		PodPersistentVolume: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.name == null) {
					errors.push(new RAMLError(path.concat(['name']), context, "PROP_IS_MISSING", {name: 'name'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineStringType_8cef7a2a976678a19a8d836c3a53c236(value.name, path.concat(['name'])));
				}
				if (value.persistent == null) {
					errors.push(new RAMLError(path.concat(['persistent']), context, "PROP_IS_MISSING", {name: 'persistent'}));
				} else {
					errors = errors.concat(Validators.PersistentVolumeInfo(value.persistent, path.concat(['persistent'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "name": "some value",
		 *   "secret": "some value"
		 * }
		 */
		PodSecretVolume: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.name == null) {
					errors.push(new RAMLError(path.concat(['name']), context, "PROP_IS_MISSING", {name: 'name'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineStringType_8cef7a2a976678a19a8d836c3a53c236(value.name, path.concat(['name'])));
				}
				if (value.secret == null) {
					errors.push(new RAMLError(path.concat(['secret']), context, "PROP_IS_MISSING", {name: 'secret'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineStringType_8cef7a2a976678a19a8d836c3a53c236(value.secret, path.concat(['secret'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "name": "some value",
		 *   "host": "some value"
		 * }
		 */
		PodHostVolume: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.name == null) {
					errors.push(new RAMLError(path.concat(['name']), context, "PROP_IS_MISSING", {name: 'name'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineName_e959b65d3a390d46cacd062be5baabe7(value.name, path.concat(['name'])));
				}
				if (value.host == null) {
					errors.push(new RAMLError(path.concat(['host']), context, "PROP_IS_MISSING", {name: 'host'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineStringType_d41d8cd98f00b204e9800998ecf8427e(value.host, path.concat(['host'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "name": "some value"
		 * }
		 */
		PodEphemeralVolume: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.name == null) {
					errors.push(new RAMLError(path.concat(['name']), context, "PROP_IS_MISSING", {name: 'name'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineName_e959b65d3a390d46cacd062be5baabe7(value.name, path.concat(['name'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "shell": "some value"
		 * }
		 */
		MesosCommand: function(value, path) {
			var errors = [];
			path = path || [];
			return errors;
		},
	
		/**
		 * {
		 *   "name": "some value",
		 *   "containerPort": 1,
		 *   "hostPort": 1,
		 *   "protocol": [
		 *     "some value"
		 *   ],
		 *   "labels": {},
		 *   "networkNames": [
		 *     "some value"
		 *   ]
		 * }
		 */
		Endpoint: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.name == null) {
					errors.push(new RAMLError(path.concat(['name']), context, "PROP_IS_MISSING", {name: 'name'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineName_e959b65d3a390d46cacd062be5baabe7(value.name, path.concat(['name'])));
				}
				if (value.containerPort != null) {
					errors = errors.concat(PrivateValidators.inlinePort_8a1fd38080fb8d93e0f812222c697fb9(value.containerPort, path.concat(['containerPort'])));
				}
				if (value.hostPort != null) {
					errors = errors.concat(PrivateValidators.inlineAnyPort_f0c659cc7c5ba17b44d6bdf5278be7bf(value.hostPort, path.concat(['hostPort'])));
				}
				if (value.protocol != null) {
					errors = errors.concat(PrivateValidators.inlineName_5023763c58cb378ebf05c94ca2bf36a7(value.protocol, path.concat(['protocol'])));
				}
				if (value.labels != null) {
					errors = errors.concat(Validators.KVLabels(value.labels, path.concat(['labels'])));
				}
				if (value.networkNames != null) {
					errors = errors.concat(PrivateValidators.inlineName_c16b318f4fe8b85b2a89ed06127bb779(value.networkNames, path.concat(['networkNames'])));
				}
			}
			return errors;
		},
	
		/**
		 * "some value"
		 */
		ImageType: function(value, path) {
			var errors = [];
			path = path || [];
			if (typeof value != "string") {
				errors.push(new RAMLError(path, context, "TYPE_NOT_STRING"));
			} else {
				if (context.ENUMS[5].indexOf(value.toLowerCase()) === -1) {
					errors.push(new RAMLError(path, context, "ENUM", {"values":"docker, appc"}));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "secret": "some value"
		 * }
		 */
		DockerPullConfig: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.secret == null) {
					errors.push(new RAMLError(path.concat(['secret']), context, "PROP_IS_MISSING", {name: 'secret'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineStringType_8cef7a2a976678a19a8d836c3a53c236(value.secret, path.concat(['secret'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "endpoint": "some value",
		 *   "path": "some value",
		 *   "scheme": "HTTP"
		 * }
		 */
		HttpCheck: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.endpoint == null) {
					errors.push(new RAMLError(path.concat(['endpoint']), context, "PROP_IS_MISSING", {name: 'endpoint'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineName_e959b65d3a390d46cacd062be5baabe7(value.endpoint, path.concat(['endpoint'])));
				}
				if (value.path != null) {
					errors = errors.concat(Validators.Path(value.path, path.concat(['path'])));
				}
				if (value.scheme != null) {
					errors = errors.concat(PrivateValidators.inlineHttpScheme_9daecb70f32aeec3a0f8567d3168714f(value.scheme, path.concat(['scheme'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "endpoint": "some value"
		 * }
		 */
		TcpCheck: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.endpoint == null) {
					errors.push(new RAMLError(path.concat(['endpoint']), context, "PROP_IS_MISSING", {name: 'endpoint'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineName_e959b65d3a390d46cacd062be5baabe7(value.endpoint, path.concat(['endpoint'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "command": {
		 *     "shell": "some value"
		 *   }
		 * }
		 */
		CommandCheck: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.command == null) {
					errors.push(new RAMLError(path.concat(['command']), context, "PROP_IS_MISSING", {name: 'command'}));
				} else {
					errors = errors.concat(Validators.MesosCommand(value.command, path.concat(['command'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "name": "some value",
		 *   "mountPath": "some value",
		 *   "readOnly": true
		 * }
		 */
		VolumeMount: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.name == null) {
					errors.push(new RAMLError(path.concat(['name']), context, "PROP_IS_MISSING", {name: 'name'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineName_e959b65d3a390d46cacd062be5baabe7(value.name, path.concat(['name'])));
				}
				if (value.mountPath == null) {
					errors.push(new RAMLError(path.concat(['mountPath']), context, "PROP_IS_MISSING", {name: 'mountPath'}));
				} else {
					errors = errors.concat(PrivateValidators.inlinePath_293be7de6c009b1ca492ed5c5b0eafa1(value.mountPath, path.concat(['mountPath'])));
				}
				if (value.readOnly != null) {
					errors = errors.concat(Validators.BooleanType(value.readOnly, path.concat(['readOnly'])));
				}
			}
			return errors;
		},
	
		/**
		 * "{\n  \"uri\": \"http://download.me/file.tgz\",\n  \"extract\": true,\n  \"executable\": true,\n  \"cache\": false\n}\n"
		 */
		Artifact: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.uri == null) {
					errors.push(new RAMLError(path.concat(['uri']), context, "PROP_IS_MISSING", {name: 'uri'}));
				} else {
					errors = errors.concat(Validators.Uri(value.uri, path.concat(['uri'])));
				}
				if (value.extract != null) {
					errors = errors.concat(PrivateValidators.inlineBooleanType_d41d8cd98f00b204e9800998ecf8427e(value.extract, path.concat(['extract'])));
				}
				if (value.executable != null) {
					errors = errors.concat(PrivateValidators.inlineBooleanType_d41d8cd98f00b204e9800998ecf8427e(value.executable, path.concat(['executable'])));
				}
				if (value.cache != null) {
					errors = errors.concat(PrivateValidators.inlineBooleanType_d41d8cd98f00b204e9800998ecf8427e(value.cache, path.concat(['cache'])));
				}
				if (value.destPath != null) {
					errors = errors.concat(Validators.Path(value.destPath, path.concat(['destPath'])));
				}
			}
			return errors;
		},
	
		/**
		 * {
		 *   "type": "some value",
		 *   "size": 1,
		 *   "maxSize": 1,
		 *   "profileName": "some value",
		 *   "constraints": [
		 *     [
		 *       "some value"
		 *     ]
		 *   ]
		 * }
		 */
		PersistentVolumeInfo: function(value, path) {
			var errors = [];
			path = path || [];
			if ((typeof value != "object") || (value === null)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_OBJECT"));
			} else {
				if (value.type != null) {
					errors = errors.concat(Validators.PersistentVolumeType(value.type, path.concat(['type'])));
				}
				if (value.size == null) {
					errors.push(new RAMLError(path.concat(['size']), context, "PROP_IS_MISSING", {name: 'size'}));
				} else {
					errors = errors.concat(PrivateValidators.inlineIntegerType_b6d3b04861cdc2c21823de4e53983492(value.size, path.concat(['size'])));
				}
				if (value.maxSize != null) {
					errors = errors.concat(PrivateValidators.inlineIntegerType_b6d3b04861cdc2c21823de4e53983492(value.maxSize, path.concat(['maxSize'])));
				}
				if (value.profileName != null) {
					errors = errors.concat(PrivateValidators.inlineStringType_d41d8cd98f00b204e9800998ecf8427e(value.profileName, path.concat(['profileName'])));
				}
				if (value.constraints != null) {
					errors = errors.concat(PrivateValidators.inlineVolumeConstraint_3b1f04aede5a1e865648311c626a62e2(value.constraints, path.concat(['constraints'])));
				}
			}
			return errors;
		},
	
		/**
		 * "some value"
		 */
		Path: function(value, path) {
			var errors = [];
			path = path || [];
			if (typeof value != "string") {
				errors.push(new RAMLError(path, context, "TYPE_NOT_STRING"));
			} else {
				if (value.length < 1) {
					errors.push(new RAMLError(path, context, "LENGTH_MIN", {"value":1}));
				}
				if (value.length > 1024) {
					errors.push(new RAMLError(path, context, "LENGTH_MAX", {"value":1024}));
				}
			}
			return errors;
		},
	
		/**
		 * true
		 */
		BooleanType: function(value, path) {
			var errors = [];
			path = path || [];
			if ((value !== false) && (value !== true)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_BOOLEAN"));
			} else {
			}
			return errors;
		},
	
		/**
		 * "some value"
		 */
		Uri: function(value, path) {
			var errors = [];
			path = path || [];
			if (typeof value != "string") {
				errors.push(new RAMLError(path, context, "TYPE_NOT_STRING"));
			} else {
				if (value.length < 1) {
					errors.push(new RAMLError(path, context, "LENGTH_MIN", {"value":1}));
				}
				if (value.length > 1024) {
					errors.push(new RAMLError(path, context, "LENGTH_MAX", {"value":1024}));
				}
			}
			return errors;
		},
	
		/**
		 * "some value"
		 */
		PersistentVolumeType: function(value, path) {
			var errors = [];
			path = path || [];
			if (typeof value != "string") {
				errors.push(new RAMLError(path, context, "TYPE_NOT_STRING"));
			} else {
				if (context.ENUMS[7].indexOf(value.toLowerCase()) === -1) {
					errors.push(new RAMLError(path, context, "ENUM", {"values":"root, path, mount"}));
				}
			}
			return errors;
		},
	
		/**
		 * "some value"
		 */
		Name: function(value, path) {
			var errors = [];
			path = path || [];
			if (typeof value != "string") {
				errors.push(new RAMLError(path, context, "TYPE_NOT_STRING"));
			} else {
				if (!context.REGEX[3].exec(value)) {
					errors.push(new RAMLError(path, context, "STRING_PATTERN", {"pattern":"^[a-z0-9]([-a-z0-9]*[a-z0-9])?$"}));
				}
				if (value.length > 63) {
					errors.push(new RAMLError(path, context, "LENGTH_MAX", {"value":63}));
				}
				if (value.length < 1) {
					errors.push(new RAMLError(path, context, "LENGTH_MIN", {"value":1}));
				}
			}
			return errors;
		},
	
		/**
		 * [
		 *   "some value"
		 * ]
		 */
		VolumeConstraint: function(value, path) {
			var errors = [];
			path = path || [];
			if (!Array.isArray(value)) {
				errors.push(new RAMLError(path, context, "TYPE_NOT_ARRAY"));
			} else {
				errors = value.reduce(function(errors, value, i) {
					return errors.concat(
						Validators.StringType(value, path.concat([i]))
					);
				}, errors);
				if (value.length < 2) {
					errors.push(new RAMLError(path, context, "ITEMS_MIN", {"value":2}));
				}
				if (value.length > 3) {
					errors.push(new RAMLError(path, context, "ITEMS_MAX", {"value":3}));
				}
			}
			return errors;
		},
	
	};
	
	// Expose validator functions, bound to local overrides
	Object.keys(Validators).forEach((function(key) {
		this[key] = Validators[key];
	}).bind(this));
	
	// Expose .clone function that allows further overrides to apply
	this.clone = function(cloneConfig) {
		return new RAMLValidator(Object.assign(config, cloneConfig));
	}
}

return new RAMLValidator();
})();