// put in your config overrides here
export default {
  applicationRenderDelay: 100,
  rootUrl: "",
  historyServer: "",
  analyticsKey: "39uhSEOoRHMw6cMR6st9tYXDbAL3JSaP",
  stateRefresh: 3000,
  useFixtures: false,
  useUIConfigFixtures: false
};
