export enum ServiceActionItem {
  CREATE = "create",
  CREATE_GROUP = "create_group",
  EDIT = "edit",
  DELETE = "delete",
  OPEN = "open",
  RESTART = "restart",
  RESUME = "resume",
  SCALE = "scale",
  STOP = "stop",
  MORE = "more",
  KILL_POD_INSTANCES = "kill_pod_instances",
  KILL_TASKS = "kill_tasks"
}
