const KeyboardUtil = {
  keyCodes: {
    leftArrow: 37,
    rightArrow: 39,
    leftBracket: 219,
    enter: 13
  }
};

module.exports = KeyboardUtil;
