import { i18nMark } from "@lingui/react";

const TaskDirectoryHeaderLabels = {
  path: i18nMark("Name"),
  mode: i18nMark("Permissions"),
  uid: i18nMark("Owner"),
  size: i18nMark("Size"),
  mtime: i18nMark("Modified")
};

module.exports = TaskDirectoryHeaderLabels;
