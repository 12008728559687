export const AGENT_ADDED = "AGENT_ADDED";
export const AGENT_REMOVED = "AGENT_REMOVED";
export const FRAMEWORK_ADDED = "FRAMEWORK_ADDED";
export const FRAMEWORK_REMOVED = "FRAMEWORK_REMOVED";
export const FRAMEWORK_UPDATED = "FRAMEWORK_UPDATED";
export const GET_AGENTS = "GET_AGENTS";
export const GET_EXECUTORS = "GET_EXECUTORS";
export const GET_FRAMEWORKS = "GET_FRAMEWORKS";
export const GET_MASTER = "GET_MASTER";
export const GET_STATE = "GET_STATE";
export const GET_TASKS = "GET_TASKS";
export const SUBSCRIBED = "SUBSCRIBED";
export const TASK_ADDED = "TASK_ADDED";
export const TASK_UPDATED = "TASK_UPDATED";
export const UNKNOWN = "UNKNOWN";
