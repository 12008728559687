import Config from "#SRC/js/config/Config";
import Item from "#SRC/js/structs/Item";
import { getWebURL } from "#PLUGINS/services/src/js/utils/ServiceUtil";

import HealthStatus from "../constants/HealthStatus";
import ServiceImages from "../constants/ServiceImages";
import * as ServiceStatus from "../constants/ServiceStatus";
import ServiceSpec from "./ServiceSpec";
import VolumeList from "./VolumeList";

module.exports = class Service extends Item {
  constructor() {
    super(...arguments);
    this._regions = undefined;
  }
  getId() {
    return this.get("id") || "";
  }

  getMesosId() {
    return this.getId()
      .split("/")
      .slice(1)
      .join("_");
  }

  getName() {
    return this.getId()
      .split("/")
      .pop();
  }

  getSpec() {
    return new ServiceSpec(this.get());
  }

  getHealth() {
    return HealthStatus.NA;
  }

  getLabels() {
    return {};
  }

  getVolumes() {
    return new VolumeList({ items: [] });
  }

  getStatus() {
    return this.getServiceStatus().displayName;
  }

  getServiceStatus() {
    return ServiceStatus.NA;
  }

  getRegions() {
    if (!this._regions) {
      const regionCounts = (this.get("tasks") || []).reduce(
        (regions, { region }) => {
          if (region) {
            regions[region] = regions[region] ? regions[region] + 1 : 1;
          }

          return regions;
        },
        {}
      );

      this._regions = Object.keys(regionCounts).sort();
    }

    return this._regions;
  }

  getImages() {
    return ServiceImages.NA_IMAGES;
  }

  getQueue() {
    return null;
  }

  getWebURL() {
    return getWebURL(this.getLabels(), Config.rootUrl);
  }

  getVersion() {
    return "";
  }

  getInstancesCount() {
    return 0;
  }

  getRunningInstancesCount() {
    return (this.get("tasks") || []).length;
  }

  getTasksSummary() {
    return {
      tasksHealthy: 0,
      tasksStaged: 0,
      tasksUnhealthy: 0,
      tasksUnknown: 0,
      tasksOverCapacity: 0,
      tasksRunning: 0
    };
  }

  getResources() {
    const instances = this.getInstancesCount();
    const {
      cpus = 0,
      mem = 0,
      gpus = 0,
      disk = 0
    } = this.getSpec().getResources();

    return {
      cpus: cpus * instances,
      mem: mem * instances,
      gpus: gpus * instances,
      disk: disk * instances
    };
  }

  toJSON() {
    return this.get();
  }
};
